<script>
  /* eslint-disable vue/script-indent */
  import createGuardMixin from '@/helpers/createGuardMixin'

  const guardMixin = createGuardMixin(async ({ store, route, redirect }) => {
    // Redirect to the first non completed choice (or to the summary if they're all completed)
    store.set('customize/mockRoute', route)
    const firstNonCompletedChoiceIndex = store.get('customize/firstNonCompletedChoiceIndex')
    const destination =
      firstNonCompletedChoiceIndex !== -1
        ? {
            name: 'CustomizeChoice',
            params: { ...route.params, choice: (firstNonCompletedChoiceIndex || 0) + 1 },
          }
        : {
            name: 'CustomizeSummary',
            params: route.params,
          }
    store.set('customize/mockRoute', null)
    return redirect(destination)
  })

  export default {
    mixins: [guardMixin],
  }
</script>
